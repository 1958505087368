

const appointmentLinkSharingRoutes = [
    {
        path: '/',
        component: () => import('../components/layouts/appointmentRoomLayout.vue'),
        children: [
            {
                path: 'appointmentLink/:bookingID',
                name: 'appointmentLinkSharingRoom',
                visibility: false,
                authentication: true,
                component: () => import('../views/appointmentVideoCallRoomPages/appointmentDetails.vue')
            },
           {
            path: '/videocallended',
            name: 'videoCallEndPage',
            visibility: false,
            authentication: true,
            component: () => import('../views/appointmentVideoCallRoomPages/videoCallEndPage.vue')
           }
        ]
    },
   
]
module.exports = {
    appointmentLinkSharingRoutes
}